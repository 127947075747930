import { Box, List } from "@mui/material"
import { Dropdown, Icon, TColors, TColorsMain, Typography } from "cakemail-ui-components-v2"
import { TIconName } from "cakemail-ui-components-v2/dist/esm/components/icon/types"
import { useCallback, useContext, useEffect, useMemo, useState } from "react"
import { useTranslation } from "react-i18next"
import { CustomerContext, PricingPageContext } from "../../contexts"
import { IPrice } from "../../interfaces/IPrice"
import { FeatureDefinitions } from "../../models/FeatureDefinitions"
import { Messages } from "../../models/Messages"
import { EFeatureCategory } from "../../types/EFeatureCategory"
import { FullFeatureSet } from "../FullFeatureSet"
import { checkIfPriceIsLegacyPlan } from "../lib/checkIfPriceIsLegacyPlan"
import { overrideLimitsDisplay } from "../lib/overrideLimitsDisplay"
import './styles.scss'

export default function FeaturesDisplay({ price }: { price: IPrice }) {
    const customerContext = useContext(CustomerContext)
    const { t: translate } = useTranslation()
    const [currentlyClickedFeature, setCurrentlyClickedFeature] = useState<any>({
        featureName: "",
        planType: ""
    })
    const pricingPageContext = useContext(PricingPageContext)

    useEffect(() => {
        Messages.sendHeightToParent()
    }, [currentlyClickedFeature])

    const getFeatureStyling = (feature: string) => {
        return feature ?
            {
                color: "secondary" as TColors,
                icon: "Checkmark14" as TIconName,
                typographyColor: "body1.main" as TColorsMain,

            } :
            {
                color: "body2" as TColors,
                icon: "Remove14" as TIconName,
                typographyColor: "body2.main" as TColorsMain,
            }
    }

    const showDescription = (feature: any) => {
        if (currentlyClickedFeature.featureName === feature
            && currentlyClickedFeature.planType === price?.metadata?.category) {
            setCurrentlyClickedFeature({
                featureName: "",
                planType: ""
            })
        }
        else {
            setCurrentlyClickedFeature({
                featureName: feature,
                planType: price?.metadata?.category
            })
        }
    }

    function changeSelectedContactTier(event: any, newValue: any) {
        if (pricingPageContext && newValue) pricingPageContext.setSelectecContactTier(Number(newValue.props.value))
    }

    const settingFeaturesNotFromAPI = useCallback((planType: IPrice) => {
        let tempFeatureSet = new FullFeatureSet(planType)
        if (planType?.metadata?.category === "premium") {
            tempFeatureSet.dedicated_sending_ip_option = true
            tempFeatureSet.live_chat_support = true
            tempFeatureSet.priority_phone_support = true
            tempFeatureSet.professional_templates = true
            tempFeatureSet.insert_reseller_logo = true
        }
        if (planType?.metadata?.category === "growth") {
            tempFeatureSet.live_chat_support = true
            tempFeatureSet.professional_templates = true
            tempFeatureSet.insert_reseller_logo = true
        }
        if (planType?.metadata?.category === "free" && (customerContext?.brand.partnerUrl === "app.courrielleur.com" || customerContext?.brand.partnerUrl === "app.cakemail.com" || customerContext?.brand.partnerUrl === "communications.yapla.com")) {
            tempFeatureSet.drip_campaigns = false
        }
        overrideLimitsDisplay(planType?.metadata?.category, tempFeatureSet)

        return tempFeatureSet
    }, [customerContext?.brand.partnerUrl])
    // Memoize fullFeatureSet calculation to prevent unnecessary recalculations
    const fullFeatureSet = useMemo(() => {
        if (price) {
            return settingFeaturesNotFromAPI(price).getFeaturesAndLimits()
        }
        return null
    }, [price, settingFeaturesNotFromAPI])

    const featuresOnlyInPremium = useMemo(() => {
        if (fullFeatureSet) {
            // Handle setting the premium product
            const premiumProduct = customerContext?.products[0]?.prices?.find((price) => price?.metadata?.category === "premium")
            if (premiumProduct?.metadata?.usage_limits) {
                premiumProduct.metadata.usage_limits = settingFeaturesNotFromAPI(premiumProduct)
            }

            const growthProduct = customerContext?.products[0]?.prices?.find((price) => price?.metadata?.category === "growth")
            if (growthProduct?.metadata?.usage_limits) {
                growthProduct.metadata.usage_limits = settingFeaturesNotFromAPI(growthProduct)
            }

            const freeProduct = customerContext?.products[0]?.prices?.find((price) => price?.metadata?.category === "free")
            if (freeProduct?.metadata?.usage_limits) {
                freeProduct.metadata.usage_limits = settingFeaturesNotFromAPI(freeProduct)
            }

            // Find features in the premium product that are not in the growth product
            const featureNotInGrowth = Object.keys(premiumProduct?.metadata?.usage_limits).filter((feature) => {
                return !growthProduct?.metadata?.usage_limits[feature]
            })

            // Find features in the premium product that are not in the free product
            const featureOnlyInPremium = Object.keys(premiumProduct?.metadata?.usage_limits).filter((feature) => {
                return !freeProduct?.metadata?.usage_limits[feature]
            })

            // Merge keys while ensuring there are no duplicates
            const mergedKeys = [...featureNotInGrowth, ...featureOnlyInPremium].filter((key, index, array) => array.indexOf(key) === index)

            // Remove 'email_api' feature if it exists
            const index = mergedKeys.indexOf("email_api")
            if (index !== -1) {
                mergedKeys.splice(index, 1)
            }

            return mergedKeys // Return the final list of features
        }
        return [] // Return an empty array if fullFeatureSet is not defined
    }, [fullFeatureSet, customerContext?.products, settingFeaturesNotFromAPI])


    function displayingFeaturesWithCorrectIcon() {
        return featuresOnlyInPremium?.map((feature: any) => {
            const styling = getFeatureStyling(fullFeatureSet[feature])
            if (FeatureDefinitions[feature]?.label) {
                return <>
                    <div key={feature} onClick={() => showDescription(feature)}>
                        {FeatureDefinitions[feature]?.label &&
                            <Box className="icon-container">
                                <Box className="icon-text-box">
                                    <Icon className="small-icon" name={styling.icon} color={styling.color} />
                                    <Typography color={styling.typographyColor} className="billing-underline_label" variant="body1L">
                                        {translate("feature-" + FeatureDefinitions[feature]?.label)}
                                    </Typography>
                                </Box>
                                {feature === currentlyClickedFeature.featureName && currentlyClickedFeature.planType === price?.metadata?.category
                                    && <Typography className="feature-description" variant="body1L"> {translate(`feature-${FeatureDefinitions[feature]?.label}-description`)}</Typography>}
                            </Box>
                        }
                    </div>
                </>
            }
            else {
                return <></>
            }
        })
    }

    return (
        <>
            <List>
                <div className="plan-limits-container" key={EFeatureCategory.Fundamentals}>
                    <Typography className="category" sx={{ textTransform: "none" }} variant="h3S">
                        {" "}
                        {translate("category-" + EFeatureCategory.Fundamentals)}
                    </Typography>

                    {fullFeatureSet &&
                        Object.keys(FeatureDefinitions).map((feature) => {
                            if (
                                FeatureDefinitions[feature].category === EFeatureCategory.Fundamentals &&
                                (fullFeatureSet[feature] ||
                                    (typeof FeatureDefinitions[feature].value ===
                                        "number" &&
                                        fullFeatureSet[feature] !== 0))
                            ) {
                                return (
                                    <div className="plan-feature" key={feature} onClick={(e) => {
                                    }}>
                                        <div className="fundamental-features" >
                                            <div>
                                                <Typography variant="body1L">
                                                    {translate("feature-" + FeatureDefinitions[feature]?.label)}
                                                </Typography>
                                            </div>
                                            {(FeatureDefinitions[feature]?.label !== "contact_limit" || price?.metadata?.category === "free" || checkIfPriceIsLegacyPlan(customerContext, pricingPageContext, price))
                                                ?
                                                <Typography variant="body1L">
                                                    {fullFeatureSet[feature] === -1
                                                        ? translate('features-unlimited')
                                                        : typeof fullFeatureSet[feature] === "number"
                                                            ? fullFeatureSet[feature].toLocaleString()
                                                            : ""}
                                                </Typography>
                                                : <Dropdown
                                                    className="contact-selector-in-card"
                                                    disabled={(customerContext?.authenticatedMode && customerContext?.updating) || pricingPageContext?.disabledButtons}
                                                    value={pricingPageContext?.selectedContactTier ? pricingPageContext?.selectedContactTier.toString() : undefined}
                                                    onChange={changeSelectedContactTier}
                                                    options={pricingPageContext?.contactTiers?.map((item) => ({
                                                        text: item.toLocaleString(),
                                                        value: item.toString(),
                                                    }))}
                                                    size="small"
                                                    variant="standard"
                                                />
                                            }
                                        </div>
                                    </div>
                                )
                            } else {
                                return null
                            }
                        })}
                </div>
                <Typography className="category" variant="h3S" classes="bold">{translate("category-key_differences")}</Typography>
                {fullFeatureSet && featuresOnlyInPremium && displayingFeaturesWithCorrectIcon()}
            </List>
        </>
    )
}
