import { FullFeatureSet } from "../FullFeatureSet"

/**
 * Filters an array of prices based on the specified category.
 * @param planType - Current plan type ex: Free, Growth, Premium
 * @param category - Features
 * @returns The filtered array of prices.
 */
export function overrideLimitsDisplay(planType: string, features: FullFeatureSet) {
    if (planType === "free" && window.sessionStorage.getItem("isAffiliate") == "true") {
        features.per_month = 10000
        features.maximum_contacts = 2000
    }
    return features
}
