import { Card } from "@mui/material"
import { Button, Chip, Dropdown, EEvents, GenericWrapperContext, LoadingContainer, setStorage, trackEvent, Typography } from "cakemail-ui-components-v2"
import { TButtonRef } from "cakemail-ui-components-v2/dist/esm/components/button/types"
import { MouseEvent, useContext, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { Subscription } from "../../../../functions/src/models/Subscription"
import { SubscriptionItem } from "../../../../functions/src/models/SubscriptionItem"
import { CustomerContext, PricingPageContext } from "../../contexts"
import {
    changeSubscription,
    createCheckoutSession,
    createContract,
    previewSubscription,
    refreshTokenIfNecessary
} from "../../functions"
import { IPrice } from "../../interfaces/IPrice"
import { IProduct } from "../../interfaces/IProduct"
import { Messages } from "../../models/Messages"
import { buildPHBillingInfo, getHttpsCallableError } from "../../utils/data"
import ChangePlan from "../ChangePlan"
import FeaturesDisplay from "../FeatureDisplay"
import LegacyCardContent from "../LegacyCardContent"
import { SimpleDialog } from "../SimpleDialog"
import { getPricesForLowestContactTier } from "../lib/getPricesForLowestContactTier"
import "./styles.scss"


export default function CardComponent({ product, price }: { product?: IProduct, price?: IPrice }) {
    const customerContext = useContext(CustomerContext)
    const pricingPageContext = useContext(PricingPageContext)
    const genericWrapperContext = useContext(GenericWrapperContext)
    const [isOpen, setIsOpen] = useState<boolean>(false)
    const [isActive, setIsActive] = useState<boolean>(false)
    const [isPlanned, setIsPlanned] = useState<boolean>(false)
    const [direction, setDirection] = useState<string>("")
    const [plannedDate, setPlannedDate] = useState<Date>()
    const [previewDetails, setPreviewDetails] = useState<any>(null)
    const [somethingElsePlanned, setSomethingElsePlanned] = useState<boolean>(false)
    const [freePlanContacts, setFreePlanContacts] = useState<number>(2000)
    const { t: translate } = useTranslation()
    const [cardError, setCardError] = useState<object>()

    function isItActive() {
        if (!customerContext) {
            setIsActive(false)
            return
        }

        if (pricingPageContext?.contractPrice && price) {
            setIsActive(price.id === pricingPageContext?.contractPrice.id)
        } else if (price) {
            const subbed =
                customerContext.subscriptions.filter(
                    (subscription: Subscription) =>
                        subscription.items.filter((subItem: SubscriptionItem) => {
                            return subItem.price.id === price.id
                        }).length > 0
                ).length > 0

            setIsActive(
                subbed ||
                (!customerContext.getSubscription() && !!!customerContext?.updating && price.metadata.category === "free")
            )
        }
    }

    function textDisplayedOnButton(price: IPrice, product: any) {

        if (product.name === "Free" && pricingPageContext && pricingPageContext?.selectedContactTier > 2000) {
            return translate('plan-contact-limit-exceeded')
        }
        else if (isActive && somethingElsePlanned) {
            return translate('plan-status-active-until') + " " + plannedDate?.toDateString()
        }
        else if (isActive) {
            return translate('plan-status-active')
        }
        else if (!isActive && !isPlanned) {
            return actionLabel(price)
        }
        else if (isPlanned && plannedDate) {
            return translate('plan-status-cancel')
        }

        return translate('unauthenticated-plan-Free')

    }

    async function createAContract({ priceId, contractsUrl }: { priceId: string, contractsUrl: string }) {
        if (customerContext && customerContext.token) {
            return createContract({ priceId, token: customerContext.token })
                .then(({ data }: any) => {
                    const redirectURL = encodeURIComponent(`https://${customerContext.brand.partnerUrl}/#/account/plans-and-billing`)
                    Messages.requestNavigateTo(`${contractsUrl}?contract=${data}&redirectUrl=${redirectURL}`, customerContext.brand.partnerUrl)
                })
                .finally(() => {
                    pricingPageContext?.setDisableButtons(false)
                })
        }
    }

    function isItPlanned() {
        if (customerContext && price && customerContext.subscriptions.length > 0) {
            const schedule = customerContext.getSubscription().schedule
            if (schedule && schedule.phases) {
                const planned =
                    schedule.phases[schedule.phases.length - 1]?.items?.filter(
                        (subItem: any) => {
                            return (
                                subItem.price === price.id &&
                                schedule.phases[schedule.phases.length - 1].start_date >
                                new Date().getTime() / 1000
                            )
                        }
                    ).length > 0

                setIsPlanned(planned)
                setPlannedDate(
                    new Date(
                        customerContext.getSubscription().schedule?.phases[
                            customerContext.getSubscription().schedule?.phases.length - 1
                        ]?.start_date * 1000
                    )
                )
                if (planned) {
                    setPlannedDate(
                        new Date(
                            customerContext.getSubscription().schedule?.phases[
                                customerContext.getSubscription().schedule?.phases.length - 1
                            ]?.start_date * 1000
                        )
                    )
                }
            }
        }
    }

    function checkSameCategory(price: IPrice, subscription: any) {
        return (
            price?.metadata?.category ===
            subscription?.items[0]?.price?.metadata?.category
        )
    }

    function isThereSomethingElsePlanned() {
        if (customerContext && price && customerContext.subscriptions.length > 0) {
            const schedule = customerContext.getSubscription().schedule
            if (schedule && schedule.phases) {
                const planned =
                    schedule.phases[schedule.phases.length - 1]?.items?.filter(
                        (subItem: any) => {
                            return subItem.price !== price.id
                        }
                    ).length > 0

                setSomethingElsePlanned(planned)
            }
        }
    }

    function unauthenticatedButton(product: IProduct) {
        if (product.name === "Free" && pricingPageContext && pricingPageContext?.selectedContactTier > freePlanContacts) {
            return translate('plan-contact-limit-exceeded')
        }
        return translate(`unauthenticated-plan-${product.name}`)
    }
    function changeSelectedCurrency(event: any, newValue: any) {
        if (pricingPageContext && newValue) {
            pricingPageContext.setCurrency(newValue.props.value)
        }
    }

    function validateError(error: any, msgArg: object = {}) {
        const { code } = getHttpsCallableError(error)
        switch (code) {
            case "functions/failed-precondition":
                setCardError({ ...msgArg, error })
                break
        }
    }

    async function ChangePlanPreview(price: any, context: any) {
        // If the customer has a subscription, we can preview the change
        if (context && pricingPageContext && context.getSubscription()) {
            let priceId = price.id
            if (isPlanned) {
                pricingPageContext.setDisableButtons(true)
                priceId = context.getSubscription().items[0].price.id
                return changeSubscription({
                    token: context.token,
                    priceId,
                    subscriptionId: context.getSubscription().id,
                })
                    .then((a: any) => {
                        trackEvent(EEvents.PLAN_DOWNGRADE_CANCELLED, {
                            ...buildPHBillingInfo({ price, subscription: context.getSubscription() }),
                            cancelledScheduledDowngrade: true
                        })
                        context.reload()
                    })
                    .catch((error: any) => {
                        validateError(error, { priceId, subscriptionId: context.getSubscription().id })
                        refreshTokenIfNecessary(error)
                    })
                    .catch((error: any) => {
                    })
            }

            // If the price is higher than the current price, we preview the upgrade
            if (price.unit_amount > (context?.getSubscription()?.items[0]?.price?.unit_amount || 0)) {
                pricingPageContext.setDisableButtons(true)
                return previewSubscription({
                    priceId: price.id,
                    subscriptionId: context.getSubscription().id,
                    token: context.token,
                })
                    .then((r) => {
                        trackEvent(EEvents.PLAN_UPGRADE_INITIATED, buildPHBillingInfo({ price, subscription: context.getSubscription() }))
                        setPreviewDetails(r)
                        setDirection("Upgrade")
                        setIsOpen(true)
                    })
                    .catch((error: any) => {
                        validateError(error, { priceId, subscriptionId: context.getSubscription().id })
                        refreshTokenIfNecessary(error)
                    })
                    .catch((error: any) => {
                    })
            }

            // At this point, the price is not higher. We preview the downgrade if the category is the same
            if (checkSameCategory(price, context.getSubscription())) {
                pricingPageContext.setDisableButtons(true)
                return previewSubscription({
                    priceId: price.id,
                    subscriptionId: context.getSubscription().id,
                    token: context.token,
                })
                    .then((r) => {
                        trackEvent(EEvents.PLAN_DOWNGRADE_INITIATED, buildPHBillingInfo({ price, subscription: context.getSubscription() }))
                        setPreviewDetails(r)
                        setDirection("Downgrade")
                        setIsOpen(true)
                    })
                    .catch((error: any) => {
                        validateError(error, { priceId, subscriptionId: context.getSubscription().id })
                        refreshTokenIfNecessary(error)
                    })
                    .catch((error: any) => {
                    })
            }

            // At this point, the price is not higher nor the same category.  This is Customer Support assisted Downgrade
            if (price.unit_amount < (context?.getSubscription()?.items[0]?.price?.unit_amount || 0)) {
                pricingPageContext.setDisableButtons(false)
                trackEvent(EEvents.PLAN_DOWNGRADE_INITIATED, buildPHBillingInfo({ price, subscription: context.getSubscription() }))
                setPreviewDetails(null)
                setDirection("Downgrade")
                setIsOpen(true)
                return
            }

            // Here, this is a "change" - whatever that means.
            setDirection("Change")
            return
        }

        // If the customer does not have a subscription, we create a checkout session
        pricingPageContext?.setDisableButtons(true)
        return createCheckoutSession({
            token: context.token,
            priceId: price.id,
            returnUrl: "https://" + customerContext?.brand.partnerUrl + "/#/account/plans-and-billing"
        })
            .then((a: any) => {
                trackEvent(EEvents.PLAN_UPGRADE_INITIATED, {
                    ...buildPHBillingInfo({ price, subscription: context.getSubscription() }),
                    checkoutSessionCreated: true
                })
                setStorage({ key: "billing_no_subscription", data: true })
                if (a.data?.url && pricingPageContext && customerContext) {
                    Messages.requestNavigateTo(a.data.url, customerContext.brand.partnerUrl)
                } else {
                    window.location.reload()
                }
            })
            .catch((error: any) => {
                refreshTokenIfNecessary(error)
            })
    }

    function actionLabel(targetPrice: IPrice) {
        const tp = targetPrice.unit_amount
        let cp = pricingPageContext?.contractPrice?.unit_amount || customerContext?.getSubscription()?.items[0]?.price?.unit_amount || 0
        const subscriptionChange =
            tp >= cp ? (tp === cp ? "Change" : "Upgrade") : "Downgrade"
        return translate('plan-status-' + subscriptionChange)
    }

    useEffect(isItActive, [customerContext, price, pricingPageContext?.contractPrice])
    useEffect(isItPlanned, [customerContext, price])
    useEffect(isThereSomethingElsePlanned, [customerContext, price])

    function findContactTierAssociatedWithFree() {
        pricingPageContext?.products.filter(product => {
            return product.name === "Free"
        }).map(product => {
            return setFreePlanContacts(getPricesForLowestContactTier(product, pricingPageContext?.currency)?.metadata?.usage_limits?.maximum_contacts)
        })
    }

    useEffect(findContactTierAssociatedWithFree, [pricingPageContext?.currency, pricingPageContext?.products])

    /**
     * Handles the plan change process when a user selects a new plan.
     * If a contracts URL is available, creates a contract for the new plan.
     * Otherwise previews the plan change.
     * Sets loading state during the async operations.
     */
    async function handlePlanChange(event: MouseEvent<HTMLButtonElement>, btnRef: TButtonRef) {
        const contractsURL = customerContext?.profile?.billingPortalUrl || genericWrapperContext?.partnerBrand?.config?.contract_redirection_url
        btnRef.animate()
        pricingPageContext?.setDisableButtons(true)
        return changePlan(contractsURL)
            .finally(() => {
                btnRef.reset()
            })
    }

    /**
     * Changes the plan based on the contracts URL or customer context.
     * @param contractsURL - The URL for the contracts.
     * @returns A promise that resolves to the result of the change plan operation.
     */
    async function changePlan(contractsURL?: string) {
        if (!price) return
        if (contractsURL) {
            return createAContract({ priceId: price.id, contractsUrl: contractsURL })
        }
        return ChangePlanPreview(price, customerContext)
    }

    return (
        <>
            {cardError &&
                <SimpleDialog
                    open={true}
                    title={translate("plan-error-request-title")}
                    content={translate("plan-error-request-content")}
                    secondaryBtn={{
                        text: translate('plan-status-cancel'),
                        onClick: () => { setCardError(undefined) }
                    }}
                    primaryBtn={{
                        text: translate("plan-error-request-btn"),
                        onClick: () => {
                            let message = `An error has occured while trying to ${direction || "change"} a subscription.\n`
                            message += `customer : ${customerContext?.id} \n`
                            for (const [key, value] of Object.entries(cardError)) {
                                message += `${key}: ${value}\n`
                            }
                            message += "code : functions/failed-precondition \n"
                            Messages.sendZendeskTicketRequest("support", message)
                            setCardError(undefined)
                        }
                    }}
                />
            }
            {price && !product && customerContext && (
                <LegacyCardContent price={price} isActive={isActive} />
            )
            }
            {
                pricingPageContext && price && product && customerContext && (
                    <Card className="shadowed-element" style={{ background: "white" }}>
                        {isPlanned && !isActive && customerContext.authenticatedMode && (
                            <Chip className="card-chip" label={translate("plan-label-upcoming_plan")} variant="outlined" color="secondary" />
                        )}
                        {isActive && customerContext.authenticatedMode && (
                            <Chip className="card-chip" variant="outlined" label={translate("plan-label-current_plan")} color="primary" />
                        )}
                        {!isPlanned && !isActive && customerContext.authenticatedMode && (
                            <div className="chip-padding">{/* Add padding here */}</div>
                        )}
                        <div>
                            <Typography className="product-name" variant="h1">{translate(`plan-title-${product.name}`)}</Typography>
                            <div className='text-container'>
                                <div style={{ padding: "1 rem" }}>{/* Add padding here */}</div>
                                <Typography className="product-description" variant="body1L">
                                    {translate(`plan-description-${product.name}`)}
                                </Typography>
                            </div>

                            <LoadingContainer loading={Boolean(customerContext?.updating)} keepChildren={true}>
                                <div className="content-block">
                                    <div className="your-price-label">
                                        <Typography variant="body2L">
                                            {translate('plan-price-label')}
                                        </Typography>
                                    </div>
                                    <Typography variant="h2" className={`priceAmount ${pricingPageContext.currency ?? 'usd'}`}>{price.unit_amount / 100}</Typography>
                                    {product.name !== "Free" ? (
                                        <div className="currency-dropdown-container"
                                        >

                                            <Dropdown
                                                className="currency-dropdown"
                                                value={pricingPageContext.currency}
                                                onChange={changeSelectedCurrency}
                                                options={pricingPageContext.currencies?.map((item) => ({
                                                    text: item.toUpperCase(),
                                                    value: item,
                                                }))}
                                                disabled={pricingPageContext.disabledButtons}
                                                size="small"
                                                variant="standard"
                                            />
                                            <Typography variant="body2L"
                                            >/{translate('month')}</Typography>
                                        </div>
                                    ) : product.name === "Free" ? (
                                        <div className="credit-card">
                                            <Typography variant="body2L" > {translate('no-credit-card')}</Typography>
                                        </div>
                                    ) : (
                                        null
                                    )}
                                </div>
                                <div className="content-block">
                                    {customerContext?.authenticatedMode ?
                                        <Button
                                            className={`buttonStyle ${product.name}PlanBtn`}
                                            disabled={
                                                Boolean(isActive && !!customerContext?.getSubscription())
                                                || !!customerContext?.updating
                                                || !!pricingPageContext?.disabledButtons
                                                || Boolean(pricingPageContext?.selectedContactTier && product?.name === "Free" && pricingPageContext?.selectedContactTier > 2000)
                                            }
                                            color={product.name !== "Free" ? "primary" : "secondary"}
                                            variant="contained"
                                            size="small"
                                            onClick={handlePlanChange}
                                        >
                                            {textDisplayedOnButton(price, product)}
                                        </Button>
                                        : window.sessionStorage.getItem("hideButton") === "true" ? (
                                            null
                                        ) : (
                                            <Button
                                                className={`buttonStyle ${product.name}PlanBtn`}
                                                color={product.name !== "Free" ? "primary" : "secondary"}
                                                disabled={product.name === "Free" && pricingPageContext && pricingPageContext?.selectedContactTier > freePlanContacts}
                                                onClick={() => {
                                                    const contractsURL = customerContext?.profile?.billingPortalUrl || genericWrapperContext?.partnerBrand?.config?.contract_redirection_url
                                                    if (contractsURL) {
                                                        return createAContract({ priceId: price.id, contractsUrl: contractsURL })
                                                    }
                                                    let substitutePartnerUrl = customerContext?.brand?.partnerUrl === "app.staging.courrielleur.com" ? "app.courrielleur.com" : customerContext?.brand?.partnerUrl || ""
                                                    window.open(`https://${substitutePartnerUrl}/#/auth/signup`)
                                                }}
                                            >
                                                {unauthenticatedButton(product)}
                                            </Button>
                                        )}
                                </div>
                            </LoadingContainer>

                            <ChangePlan
                                open={isOpen}
                                price={price}
                                onClose={() => { pricingPageContext?.setDisableButtons(false) }}
                                setOpen={setIsOpen}
                                previewDetails={previewDetails}
                                setPreviewDetails={setPreviewDetails}
                                direction={direction}
                                validateError={validateError}
                            />
                            <FeaturesDisplay price={price} />
                        </div>
                    </Card >
                )
            }
        </>
    )
}
