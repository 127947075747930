import { IPrice } from "../../interfaces/IPrice"
import { PricingCardsContextType } from "../../interfaces/PricingCardsContext"
import { CustomerContextType } from "../../types/CustomerContextType"

/**
 * Checks if a customer is subscribed to a legacy plan.
 * @param customer - The customer context.
 * @param pricing - The pricing page
 * @returns True if the price found is a legacy price
 */
export function checkIfPriceIsLegacyPlan(customer: CustomerContextType | null, pricingPageContext: PricingCardsContextType | null, price: IPrice): boolean {
    const subscriptionPrice = customer?.getSubscription()?.items[0]?.price
    const subscription = customer?.getSubscription()
    const contractPlan = pricingPageContext?.contractPrice
    return ((price === subscriptionPrice) && subscriptionPrice && subscription.legacy) || ((price === contractPlan) && contractPlan && contractPlan.legacy)
}
