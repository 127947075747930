import { EEvents, trackEvent, Typography } from "cakemail-ui-components-v2"
import { TButtonRef } from "cakemail-ui-components-v2/dist/esm/components/button/types"
import i18next from "i18next"
import { MouseEvent, useContext, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { CustomerContext, PricingPageContext } from "../../contexts"
import { changeSubscription, refreshTokenIfNecessary } from "../../functions"
import { IBillingInformation } from "../../interfaces/IBillingInformation"
import { ICustomerAddress } from "../../interfaces/ICustomerAddress"
import { IPrice } from "../../interfaces/IPrice"
import { Messages } from "../../models/Messages"
import { buildPHBillingInfo } from "../../utils/data"
import BillingInformation from "../BillingInformation"
import { SimpleDialog } from "../SimpleDialog"

export default function ChangePlan({
    open,
    previewDetails,
    setOpen,
    setPreviewDetails,
    direction,
    price,
    validateError,
    onClose
}: {
    open: boolean
    previewDetails: any
    setOpen: (open: boolean) => void
    setPreviewDetails: (details: any) => void
    direction: string
    price: IPrice,
    validateError: Function,
    onClose?: () => void
}) {
    const context = useContext(CustomerContext)
    const pricingCards = useContext(PricingPageContext)
    const [formattedDate, setFormattedDate] = useState<Date>()
    const [formattedPeriodStart, setFormattedPreriodStart] = useState<Date>()
    const [description, setDescription] = useState<string>()
    const [changedPrice, setChangedPrice] = useState<string>()
    const { t: translate } = useTranslation()
    const [billingInformation, setBillingInformation] = useState<IBillingInformation>()
    const [disabledButtons, setDisabledButtons] = useState(false)

    const handleClose = () => {
        setPreviewDetails(null)
        setOpen(false)
        onClose && onClose()
    }

    async function handleSubscriptionChange(event: MouseEvent<HTMLButtonElement>, btnRef: TButtonRef) {
        if (!pricingCards || !context) return

        let priceId = price.id
        setDisabledButtons(true)
        btnRef.animate()

        await changeSubscription({
            token: context.token,
            priceId,
            subscriptionId: context?.getSubscription().id,
        })
            .then((a: any) => {
                trackEvent(direction === "Upgrade" ? EEvents.PLAN_UPGRADE : EEvents.PLAN_DOWNGRADE_SCHEDULED, buildPHBillingInfo({ price, subscription: context?.getSubscription() }))
                context?.reload()
                setDisabledButtons(false)
                handleClose()
            })
            .catch((error: any) => {
                validateError(error, { priceId, subscriptionId: context?.getSubscription().id })
                refreshTokenIfNecessary(error)
            })
            .catch((error: any) => {
                setDisabledButtons(false)
                context?.setUpdating(false)
            })
            .finally(() => {
                btnRef?.reset()
            })
    }

    /**
     * Converts a Unix timestamp to a Date object.
     * @param unixTimestamp The Unix timestamp to convert.
     * @returns The corresponding Date object.
     */
    function convertUnixTimestampToDate(unixTimestamp: number): Date {
        const milliseconds = unixTimestamp * 1000
        const date = new Date(milliseconds)
        return date
    }

    /**
     * Expresses the given amount in the localized currency and language.
     * 
     * @param amount - The amount to be expressed.
     * @param currency - The currency code.
     * @returns The amount expressed in the localized currency and language.
     */
    function expressAmountInLocalizedCurrencyAndLanguage(amount: number, currency: string) {
        return amount.toLocaleString(i18next.language, {
            style: 'currency',
            currency,
            currencyDisplay: 'symbol',
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
        })
    }

    /**
     * Expresses a date in the localized language.
     * @param date - The date to be expressed.
     * @returns The date expressed in the localized language.
     */
    function expressDateInLocalizedLanguage(date?: Date) {
        if (date)
            return date.toLocaleDateString(i18next.language, {
                day: 'numeric',
                month: 'long',
                year: 'numeric',
            })
    }
    /**
     * Expresses a date in the localized language.
     * @param date - The date to be expressed.
     * @returns The date expressed in the localized language.
     */
    function formatAddress(customer_address: ICustomerAddress) {
        let formattedAddress = ""
        for (const key in customer_address) {
            if (typeof customer_address[key as keyof ICustomerAddress] !== 'object') {
                formattedAddress += customer_address[key as keyof ICustomerAddress] + ' '
            }
        }
        return formattedAddress
    }
    function checkEmail(customer_email: string | {}) {
        let formattedEmail = ''
        if (typeof customer_email !== 'object') {
            formattedEmail += customer_email
        }
        return formattedEmail
    }

    function checkName(customer_name: string | {}) {
        let formattedName = ''
        if (typeof customer_name !== 'object') {
            formattedName += customer_name
        }
        return formattedName
    }

    useEffect(() => {
        if (previewDetails && (!formattedDate || !formattedPeriodStart || !description || !billingInformation)) {
            setFormattedDate(
                convertUnixTimestampToDate(
                    previewDetails?.data?.invoice?.lines?.data[0]?.period?.end
                )
            )
            setFormattedPreriodStart(
                convertUnixTimestampToDate(
                    previewDetails?.data?.invoice?.lines?.data[0]?.period?.start
                )
            )
            setDescription(
                previewDetails?.data?.invoice?.lines?.data[0]?.description
            )
            let price: string = expressAmountInLocalizedCurrencyAndLanguage(previewDetails?.data?.invoice?.amount_due / 100, previewDetails?.data?.invoice?.currency)
            setChangedPrice(price)
            setBillingInformation({
                customer_address: formatAddress(previewDetails?.data?.invoice?.customer_address),
                customer_name: checkName(previewDetails?.data?.invoice?.customer_name),
                customer_email: checkEmail(previewDetails?.data?.invoice?.customer_email)
            })
        }
    }, [previewDetails, price, description, formattedDate, formattedPeriodStart, billingInformation])

    return pricingCards && <>
        {previewDetails && direction === "Upgrade" &&
            <SimpleDialog
                open={open}
                handleClose={handleClose}
                title={<Typography variant="body1"><strong>{translate('plan-upgrade-title')}</strong></Typography>}
                content={
                    <>
                        <Typography variant="body1">
                            {translate('plan-upgrade-description')} <strong>{translate('plan-title-' + price?.metadata?.category)} {" "}
                                {price?.metadata?.usage_limits?.maximum_contacts.toLocaleString()} </strong>
                            {translate('plan-upgrade-description2')}{" "}
                            {changedPrice}{previewDetails?.data?.invoice?.lines.data[0].tax_amounts.length > 1 ? " " + translate('plan-upgrade-description3-1') : ''}, {translate('plan-upgrade-description3')}{" "}
                            {expressAmountInLocalizedCurrencyAndLanguage(previewDetails?.data?.invoice?.lines.data[previewDetails?.data?.invoice?.lines.data.length - 1].amount / 100, previewDetails?.data?.invoice?.currency)}
                            {" "}{translate('plan-upgrade-description4')}{" "}{expressDateInLocalizedLanguage(formattedDate)}
                        </Typography>
                        {billingInformation && <BillingInformation billingInformation={billingInformation} />}
                    </>
                }
                primaryBtn={{
                    text: translate('plan-upgrade-confirmation'),
                    disabled: disabledButtons,
                    onClick: handleSubscriptionChange
                }}
                secondaryBtn={{
                    text: translate('plan-upgrade-cancel'),
                    disabled: disabledButtons,
                    onClick: () => {
                        handleClose()
                        trackEvent(EEvents.PLAN_UPGRADE_CANCELLED, buildPHBillingInfo({ price, subscription: context?.getSubscription() }))
                    }
                }}
            />
        }

        {previewDetails && direction === "Downgrade" &&
            <SimpleDialog
                open={open}
                handleClose={handleClose}
                title={<Typography variant="body1"><strong>{translate('plan-downgrade-actual_downgrade')}</strong></Typography>}
                content={
                    <>
                        <Typography variant="body1">
                            {translate('plan-downgrade-more-description')} {context?.getSubscription()?.items[0]?.price?.metadata
                                ?.category ? (
                                <strong>
                                    {context?.getSubscription()?.items[0]?.price?.metadata.category}{" "}
                                    {context?.getSubscription().items[0]?.price?.metadata.usage_limits.maximum_contacts.toLocaleString()}{" "}
                                    {"("}{context?.profile?.currency.toUpperCase() || "CAD"}{")"}
                                </strong>
                            ) :
                                context?.getSubscription()?.legacy ? (
                                    // Render something based on another condition
                                    <strong>{translate('plan-title-Legacy')}</strong>
                                ) : (
                                    <strong>{translate('plan-title-Free')}</strong>
                                )} {translate('plan-downgrade-to')} {<strong>{price?.metadata?.category}  {price?.metadata?.usage_limits?.maximum_contacts.toLocaleString()} ({price?.currency.toUpperCase()}) </strong>}
                            {translate('plan-downgrade-description')} {expressDateInLocalizedLanguage(formattedDate)}.
                        </Typography>
                        {billingInformation && <BillingInformation billingInformation={billingInformation} />}
                    </>}
                primaryBtn={{
                    text: translate("plan-downgrade-request-now"),
                    disabled: disabledButtons,
                    onClick: handleSubscriptionChange
                }}
                secondaryBtn={{
                    text: translate('plan-downgrade-actual_downgrade_cancel'),
                    disabled: disabledButtons,
                    onClick: () => {
                        handleClose()
                        trackEvent(EEvents.PLAN_DOWNGRADE_CANCELLED, buildPHBillingInfo({ price, subscription: context?.getSubscription() }))
                    }
                }}
            />
        }

        {!previewDetails && direction === "Downgrade" &&
            <SimpleDialog
                open={open}
                handleClose={handleClose}
                title={<Typography variant="body1"><strong>{translate('plan-downgrade-request-downgrade')}</strong> </Typography>}
                content={
                    <>
                        <Typography>
                            {translate('plan-downgrade-request-description')}
                        </Typography>
                        {billingInformation && <BillingInformation billingInformation={billingInformation} />}
                    </>}
                primaryBtn={{
                    text: translate('plan-downgrade-request-confirm'),
                    disabled: disabledButtons,
                    onClick: () => {
                        Messages.sendZendeskTicketRequest("downgrade")
                        handleClose()
                        trackEvent(EEvents.PLAN_DOWNGRADE_REQUESTED, buildPHBillingInfo({ price, subscription: context?.getSubscription() }))
                    }
                }}
                secondaryBtn={{
                    text: translate('plan-downgrade-request-decline'),
                    disabled: disabledButtons,
                    onClick: () => {
                        handleClose()
                        trackEvent(EEvents.PLAN_DOWNGRADE_CANCELLED, buildPHBillingInfo({ price, subscription: context?.getSubscription() }))
                    }
                }}
            />
        }
    </>
}
