import { Subscription } from "../../../functions/src/models/Subscription"
import { IPrice } from "../interfaces/IPrice"

export function getHttpsCallableError(error: any): { code?: string, details?: any, name?: string } {
    try {
        // some properties may not exist, so defining them in the return will make sure they are kept
        const { code, details, name } = JSON.parse(JSON.stringify(error))
        return { code, details, name }
    } catch (e) {
        console.error("failed to parse error")
        return {
            code: undefined,
            details: undefined,
            name: undefined
        }
    }
}

export function buildPHBillingInfo({ price, subscription }: { price: IPrice, subscription?: Subscription }) {
    return {
        currentPrices: subscription ? subscription.items.map((p) => {
            return {
                currency: p.price.currency,
                name: p.price.name,
                price: p.price.unit_amount > 0 ? p.price.unit_amount / 100 : p.price.unit_amount
            }
        }) : { currency: "none", name: "No Subscription", price: 0 },
        targetPrice: {
            currency: price.currency,
            name: price.name,
            price: price.unit_amount > 0 ? price.unit_amount / 100 : price.unit_amount,
        }
    }
}