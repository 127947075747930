import { PricingCardsContextType } from "../../interfaces/PricingCardsContext"
import { CustomerContextType } from "../../types/CustomerContextType"

/**
 * Checks if a customer is subscribed to a legacy plan.
 * @param customer - The customer context.
 * @returns True if the customer is subscribed to a legacy plan, false otherwise.
 */
export function isSubscribedToLegacyPlan(customer: CustomerContextType | null, pricingPageContext: PricingCardsContextType | null): boolean {
    const subscriptionPrice = customer?.getSubscription()?.items[0]?.price
    const subscription = customer?.getSubscription()
    const contractPlan = pricingPageContext?.contractPrice
    return (subscriptionPrice && subscription.legacy) || (contractPlan && contractPlan.legacy)
}
