import { Container } from "@mui/material"
import { GenericWrapper, LoadingContainer, TBrandBaseStyles } from "cakemail-ui-components-v2"
import { useEffect, useState } from "react"
import { useSearchParams } from "react-router-dom"
import "./App.css"
import CustomerContextProvider from "./components/CustomerContextProvider"
import ErrorMessage from "./components/ErrorMessage"
import PricingPage from "./components/PricingPage"
import { CustomerContext } from "./contexts"
import { CustomerContextType } from "./types/CustomerContextType"

export default function App() {
    const [searchParams] = useSearchParams()
    const [brand, setBrand] = useState<string>("")
    const [styleOverride, setStyleOverride] = useState<TBrandBaseStyles>({})

    useEffect(() => {
        setBrand(searchParams.get("brandId") || brand || process.env.REACT_APP_DEFAULT_BRAND_ID || "")
        const styleFromQueryParams: TBrandBaseStyles = {}

        const params: TBrandBaseStyles = {
            headingFont: "headingFont",
            textFont: "textFont",
            primaryBrandColor: "primaryBrandColor",
            secondaryBrandColor: "secondaryBrandColor",
            bodyFontColor: "bodyFontColor",
            bodyFontColor2: "bodyFontColor2",
            errorBrandColor: "errorBrandColor",
            warningBrandColor: "warningBrandColor",
            successBrandColor: "successBrandColor",
            backgroundColor1: "backgroundColor1",
            backgroundColor2: "backgroundColor2",
        }
        Object.keys(params).forEach((key) => {
            const value = searchParams.get(key)
            if (value !== null && value !== "") {
                if (key.toLowerCase().includes('color')) {
                    styleFromQueryParams[key as keyof TBrandBaseStyles] = "#" + value
                }
                else {
                    styleFromQueryParams[key as keyof TBrandBaseStyles] = value
                }
            }
        })
        setStyleOverride(styleFromQueryParams)
    }, [searchParams, brand])

    function isLoading(customerContext: CustomerContextType | null): boolean {
        return customerContext?.products?.length === 0
    }

    return (
        <>
            {brand && (
                <GenericWrapper
                    styleOverride={styleOverride}
                    brandThemeContext="billing_portal"
                    brandId={brand}
                    brandHost={process.env.REACT_APP_BRAND_HOST}
                >
                    <CustomerContextProvider>
                        <CustomerContext.Consumer>
                            {(customerContext) => {
                                const loading = isLoading(customerContext)
                                return <LoadingContainer loading={loading}>
                                    <ErrorMessage />
                                    <Container>
                                        <PricingPage />
                                    </Container>
                                </LoadingContainer>
                            }}
                        </CustomerContext.Consumer>
                    </CustomerContextProvider>
                </GenericWrapper>
            )}
        </>
    )
}
