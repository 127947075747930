// Import the functions you need from the SDKs you need
import { getAnalytics } from "firebase/analytics"
import { initializeApp } from "firebase/app"
import { connectFunctionsEmulator, getFunctions, httpsCallable } from "firebase/functions"
import { Messages } from "./models/Messages"

// Your web app's Firebase configuration
const firebaseConfig = {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.RACT_APP_FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_FIREBASE_APP_ID,
    measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
}

// Initialize Firebase
const app = initializeApp(firebaseConfig)
const functions = getFunctions(app)
export const analytics = getAnalytics(app)

// hook emulators
if (window.location.hostname === 'localhost' || window.location.hostname === '127.0.0.1') {
    connectFunctionsEmulator(functions, "localhost", 5101)
}

export function refreshTokenIfNecessary(error: any) {
    if (error?.code === 'functions/unauthenticated') {
        Messages.requestTokenRefresh()
    }
    throw error
}

export function getAccountStats({ token }: { token: string }) {
    return httpsCallable(functions, "billing")({ functionName: 'getAccountStats', token })
}

/**
 * Retrieves the customer profile data and associated products.
 * 
 * @param {Object} options - The options for retrieving the customer profile.
 * @param {string} options.token - The token used for authentication.
 * @param {string} [options.currency] - The currency for the customer profile.
 * @returns {Promise<Object>} A promise that resolves to an object containing the customer profile data and associated products.
 */
export async function getCustomerProfile({ token, currency }: { token: string, currency?: string }): Promise<object> {
    return httpsCallable(functions, "billing")({ functionName: 'getCustomerProfile', token, currency })
}

/**
 * Decodes a JWT token and returns the decoded payload as a JavaScript object.
 * This function does not validate the signature of the token, nor does it validate
 * the token's expiration date.
 * 
 * @param jwt - The JWT token to decode.
 * @returns The decoded payload as a JavaScript object.
 */
export function looselyDecodedToken(jwt: string) {
    return JSON.parse(atob(jwt.split('.')[1]))
}

export async function getProducts({ partnerId }: { partnerId: number }) {
    // relative call to the current domain, on /cached/getProducts/:partnerId
    return fetch(`/getProducts?partnerId=${partnerId}`, { method: 'GET' })
        .then(async (response) => {
            return response.json()
        })
        .then((data) => {
            return { data }
        })
}

export default function createBillingPortalSession({ token, returnUrl }: { token: string; returnUrl?: string }) {
    return httpsCallable(functions, "billing")({ functionName: "createBillingPortalSession", token, returnUrl })
}

export async function changeSubscription({ priceId, subscriptionId, token }: { priceId: string; subscriptionId: any; token: string }) {
    return httpsCallable(functions, "billing")({ functionName: "changeSubscription", token, priceId, subscriptionId })
}

export async function createCheckoutSession({ priceId, token, returnUrl }: { priceId: string; token: string; returnUrl: string }) {
    return httpsCallable(functions, "billing")({ functionName: "createCheckoutSession", token, priceId, returnUrl })
}

export function getPriceById({ priceId, token }: { priceId: string; token: string }) {
    return httpsCallable(functions, "billing")({ functionName: "getPriceById", token, priceId })
}

export async function changeCurrency({ toCurrency, token }: { toCurrency: string; token: string }) {
    return httpsCallable(functions, "billing")({ functionName: "changeCurrency", token, toCurrency })
}

export async function previewSubscription({ priceId, subscriptionId, token }: { priceId: string; subscriptionId: string; token: string }) {
    return httpsCallable(functions, "billing")({ functionName: "previewSubscription", token, priceId, subscriptionId })
}

export async function createContract({ priceId, token, metadata }: { priceId: string; metadata?: any; token: string }) {
    return httpsCallable(functions, "billing")({ functionName: "createContract", token, priceId, metadata })
}