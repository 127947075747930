import { Box } from "@mui/material"
import { Icon, Typography } from "cakemail-ui-components-v2"
import { useContext, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { CustomerContext } from "../../contexts"
import { IPrice } from "../../interfaces/IPrice"
import { FeatureDefinitions } from "../../models/FeatureDefinitions"
import { Messages } from "../../models/Messages"
import { EFeatureCategory } from "../../types/EFeatureCategory"
import { FullFeatureSet } from "../FullFeatureSet"
import { overrideLimitsDisplay } from "../lib/overrideLimitsDisplay"
import './styles.scss'


export default function FeatureTableData({ price, category }: { price: IPrice, category: EFeatureCategory }) {
    const [fullFeatureSet, setFullFeatureSet] = useState<any>()
    const [featuresOnlyInPremium, setFeaturesOnlyInPremium] = useState<any>()
    const [currentlyClickedFeature, setCurrentlyClickedFeature] = useState<any>({
        featureName: "",
        planType: ""
    })
    const { t: translate } = useTranslation()
    const customerContext = useContext(CustomerContext)

    useEffect(() => {
        Messages.sendHeightToParent()
    }, [currentlyClickedFeature])



    useEffect(() => {
        if (price) {
            let tempFeatureSet = new FullFeatureSet(price)
            if (price?.metadata?.category === "premium") {
                tempFeatureSet.dedicated_sending_ip_option = true
                tempFeatureSet.live_chat_support = true
                tempFeatureSet.priority_phone_support = true
                tempFeatureSet.professional_templates = true
                tempFeatureSet.insert_reseller_logo = true
            }
            if (price?.metadata?.category === "growth") {
                tempFeatureSet.live_chat_support = true
                tempFeatureSet.professional_templates = true
                tempFeatureSet.insert_reseller_logo = true
            }
            if (price?.metadata?.category === "free" && (customerContext?.brand.partnerUrl === "app.courrielleur.com" || customerContext?.brand.partnerUrl === "app.cakemail.com" || customerContext?.brand.partnerUrl === "communications.yapla.com")) {
                tempFeatureSet.drip_campaigns = false
            }
            let premiumProduct = customerContext?.products[0]?.prices?.find((price) => {
                return price?.metadata?.category === "premium"
            })
            if (premiumProduct?.metadata?.usage_limits) {
                premiumProduct.metadata.usage_limits.dedicated_sending_ip_option = true
                premiumProduct.metadata.usage_limits.live_chat_support = true
                premiumProduct.metadata.usage_limits.priority_phone_support = true
                premiumProduct.metadata.usage_limits.professional_templates = true
                premiumProduct.metadata.usage_limits.live_chat_support = true
                premiumProduct.metadata.usage_limits.professional_templates = true
            }
            const keysFoundFromPremiumPlan = Object.keys(premiumProduct?.metadata?.usage_limits)
            const keysWithPlanAndHardCoded = Object.keys(tempFeatureSet.getFeaturesAndLimits())
            const mergedKeys = [...keysFoundFromPremiumPlan, ...keysWithPlanAndHardCoded].filter((key, index, array) => array.indexOf(key) === index)

            overrideLimitsDisplay(price?.metadata?.category, tempFeatureSet)
            setFeaturesOnlyInPremium((mergedKeys))
            setFullFeatureSet(tempFeatureSet.getFeaturesAndLimits())
        }
    }, [price, customerContext?.brand.partnerUrl, customerContext?.products])

    return (
        <>
            <div>
                <div key={category}>
                    <Typography className="category" sx={{ textTransform: "none" }} variant="h3S">
                        {translate("category-" + category)}
                    </Typography>
                    {featuresOnlyInPremium && Object.keys(FeatureDefinitions).map((feature) => {
                        if (FeatureDefinitions[feature]?.category === category) {
                            const isClicked = currentlyClickedFeature.featureName === feature && currentlyClickedFeature.planType === price?.metadata?.category

                            return (
                                <div
                                    key={feature}
                                    className="icon-container"
                                    onClick={() => {
                                        if (isClicked) {
                                            setCurrentlyClickedFeature({ featureName: "", planType: "" })
                                        } else {
                                            setCurrentlyClickedFeature({ featureName: feature, planType: price?.metadata?.category })
                                        }
                                    }}
                                >
                                    {FeatureDefinitions[feature]?.label && (
                                        <Box className="icon-text-box">
                                            {fullFeatureSet[feature] ? (
                                                <>
                                                    <Icon className="small-icon" name="Checkmark14" color="secondary" />
                                                    <Typography className="billing-underline_label" variant="body1L">
                                                        {translate("feature-" + FeatureDefinitions[feature]?.label)}
                                                    </Typography>
                                                </>
                                            ) : (
                                                <>
                                                    <Icon className="small-icon" name="Remove14" color="body2" />
                                                    <Typography className="billing-underline_label" color="body2.main" variant="body1L">
                                                        {translate("feature-" + FeatureDefinitions[feature]?.label)}
                                                    </Typography>
                                                </>
                                            )}
                                        </Box>
                                    )}
                                    {/* Render description directly below the item */}
                                    {isClicked && (
                                        <Box className="feature-description-2">
                                            <Typography
                                                variant="body1L"
                                            >
                                                {translate(`feature-${FeatureDefinitions[feature]?.label}-description`)}                                            </Typography>
                                        </Box>
                                    )}
                                </div>
                            )
                        }
                        else return null
                    })}
                </div>
                <br />
            </div>
        </>
    )
}
