import { Link } from "@mui/material"
import { GenericWrapperContext, Typography } from "cakemail-ui-components-v2"
import { useContext } from "react"
import { useTranslation } from "react-i18next"
import { CustomerContext, PricingPageContext } from "../../contexts"
import createBillingPortalSession, { refreshTokenIfNecessary } from "../../functions"
import { Messages } from "../../models/Messages"
import './styles.scss'


export default function CurrentSubscription() {
    const customerContext = useContext(CustomerContext)
    const genericWrapperContext = useContext(GenericWrapperContext)
    const pricingPageContext = useContext(PricingPageContext)
    const { t: translate } = useTranslation()
    /**
     * Retrieves the billing portal for the current subscription.
     * If the necessary context and token are available, it sets the disableButtons flag,
     * creates a billing portal session, and navigates to the portal URL.
     * If an error occurs, it resets the disableButtons flag and stops the updating process.
     */
    function getPortal() {
        if (customerContext && (customerContext?.profile?.billingProfileUrl || genericWrapperContext?.partnerBrand?.config?.contract_billing_profile_url)) {
            const navigateLink = customerContext?.profile?.billingProfileUrl || genericWrapperContext?.partnerBrand?.config?.contract_billing_profile_url
            Messages.requestNavigateTo(navigateLink, customerContext.brand.partnerUrl)
            return
        }

        if (pricingPageContext && customerContext && customerContext.token && !pricingPageContext.disabledButtons) {
            createBillingPortalSession({ token: customerContext.token, returnUrl: "https://" + customerContext.brand.partnerUrl + "/#/account/plans-and-billing" })
                .then((p: any) => {
                    pricingPageContext.setDisableButtons(false)
                    //window.parent.location.href = p.data.url
                    customerContext?.setUpdating(false)
                    Messages.requestNavigateTo(p.data.url, customerContext.brand.partnerUrl)
                })
                .catch((error: any) => {
                    refreshTokenIfNecessary(error)
                })
                .catch((error: any) => {
                    pricingPageContext.setDisableButtons(false)
                    customerContext?.setUpdating(false)
                })
            return
        }
    }
    function showBillingLink() {
        return customerContext?.profile?.billingProfileUrl || genericWrapperContext?.partnerBrand?.config?.contract_billing_profile_url || !genericWrapperContext?.partnerBrand?.config?.hide_portal_on_no_link
    }

    return <>
        {customerContext?.authenticatedMode ? (
            <div className="current-plan">
                <Typography variant="body1L">
                    {translate('current-plan-text')}{" "}
                    {pricingPageContext?.contractPrice ? (
                        <strong>{pricingPageContext?.contractPrice?.name}</strong> // Render the priceId if it has a value
                    ) : customerContext?.getSubscription()?.items[0]?.price?.metadata?.category ? (
                        <strong>
                            {translate('plan-title-' + customerContext.getSubscription().items[0]?.price?.metadata.category)}{" "}
                            {customerContext.getSubscription().items[0]?.price?.metadata.usage_limits.maximum_contacts.toLocaleString()}{" "}
                            {"("}{customerContext?.profile?.currency.toUpperCase() || "CAD"}{")"}
                        </strong>
                    ) : // If the first condition is false
                        (customerContext?.getSubscription()?.legacy || pricingPageContext?.contractPrice?.name === "legacy") ? (
                            // Render something based on another condition
                            <strong>{translate('plan-title-Legacy')}</strong>
                        ) : (
                            // Render 'Free' if both conditions are false
                            <strong>{translate('plan-title-Free')}</strong>
                        )}
                </Typography>
                {showBillingLink() &&
                    (
                        <div>
                            <Link className="custom-link" onClick={getPortal}>
                                {translate('view-billing-link')}
                            </Link>
                        </div>
                    )
                }
            </div>
        ) : (
            <div className="description"></div>
        )}
    </>
}