import { Box } from "@mui/material"
import { Icon, Typography } from "cakemail-ui-components-v2"
import i18next from "i18next"
import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { IPrice } from "../../interfaces/IPrice"
import { FeatureDefinitions } from "../../models/FeatureDefinitions"
import { Messages } from "../../models/Messages"
import { EFeatureCategory } from "../../types/EFeatureCategory"
import { FullFeatureSet } from "../FullFeatureSet"
import { overrideLimitsDisplay } from "../lib/overrideLimitsDisplay"
import './styles.scss'


export default function PlanLimitsRow({ price }: { price: IPrice }) {
    const [featureSet, setFeatureSet] = useState<FullFeatureSet>()
    const [currentlyClickedFeature, setCurrentlyClickedFeature] = useState<any>({
        featureName: "",
        planType: ""
    })

    const { t: translate } = useTranslation()

    useEffect(() => {
        if (price) {
            let tempFeatureSet = new FullFeatureSet(price)
            overrideLimitsDisplay(price?.metadata?.category, tempFeatureSet)
            setFeatureSet(tempFeatureSet.getFeaturesAndLimits())
        }
    }, [price])

    useEffect(() => {
        Messages.sendHeightToParent()
    }, [currentlyClickedFeature])

    const customPluralization = (count: number) => {
        // Standard English pluralization rules
        if (count === 1) {
            return '_one'  // Singular form for 1
        } else if (count > 1) {
            return '_many' // For large numbers
        } else {
            return '_other' // Default case for all other numbers
        }
    }
    return (
        <>
            <div style={{ paddingBottom: "1rem" }}>
                <Typography className="category" sx={{ textTransform: "none" }} variant="h3S">
                    {i18next.t("category-Fundamentals")}
                </Typography>
                {featureSet && Object.keys(FeatureDefinitions).map((feature) => {
                    if (FeatureDefinitions[feature]?.category === EFeatureCategory.Fundamentals) {
                        const isClicked = currentlyClickedFeature.featureName === feature && currentlyClickedFeature.planType === price?.metadata?.category
                        return (
                            <div
                                key={feature}
                                className="icon-container"
                                onClick={() => {
                                    if (isClicked) {
                                        setCurrentlyClickedFeature({ featureName: "", planType: "" })
                                    } else {
                                        setCurrentlyClickedFeature({ featureName: feature, planType: price?.metadata?.category })
                                    }
                                }}
                            >
                                <Box key={feature} className="icon-text-box">
                                    <Icon className="small-icon" name="Checkmark14" color="secondary" />
                                    <Typography className="billing-underline_label" variant="body1L">
                                        {i18next.t(`feature-table-${FeatureDefinitions[feature]?.label}${customPluralization(featureSet[feature])}`,
                                            {
                                                count: featureSet[feature].toLocaleString()
                                            }
                                        )}
                                    </Typography>
                                </Box>
                                {isClicked && (
                                    <Box className="feature-description-2">
                                        <Typography
                                            variant="body1L"
                                        >
                                            {translate(`feature-table-${FeatureDefinitions[feature]?.label}-description`)}                                            </Typography>
                                    </Box>
                                )}
                            </div>
                        )
                    }
                    else return null
                })}
            </div>
        </>
    )
}
